import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { DeliveryType } from '@ppgt/web/shared/domain';
import { AlertService } from '@ppgt/web/shared/core';
import { HttpService } from '@app/core/http/http.service';
import { DeliveriesService } from '@app/deliveries/deliveries.service';
import { ManualModeService } from '@app/manual-mode/manual-mode.service';
import * as fromAppState from '@app/state';
import * as actions from './deliveries.action';
import { TranslateItemsService } from '@app/shared/@services/translate-items.service';
import { CreateInspectionResponse, Delivery, DeliveryMaterial } from '../interfaces';
import { errors } from '@app/core/http/errors';
import { UpdateDeliveryFilesReq } from '@app/core/http/interfaces';
import { UpdateStageInDeliveryRun } from '@app/state/delivery-runs';

@Injectable()
export class DeliveriesEffects {
  constructor(
    private http: HttpService,
    private actions$: Actions<actions.DeliveriesAction>,
    private deliveriesService: DeliveriesService,
    private alertService: AlertService,
    private manualModeService: ManualModeService,
    private translateItems: TranslateItemsService
  ) {}

  getDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERIES),
      map((action: actions.GetDeliveries) => action.payload),
      switchMap(({ params }) =>
        this.http.getDeliveries(params).pipe(
          map((res) => {
            const deliveries = res.deliveries.map((delivery) => {
              const deliveryMaterials = delivery.deliveryMaterials.map((deliveryMaterial) =>
                this.translateItems.mapDeliveryMaterial(deliveryMaterial)
              );
              return { ...delivery, deliveryMaterials };
            });
            return new actions.GetDeliveriesSuccess({ deliveries, meta: res.meta });
          }),
          catchError((error) => [new actions.GetDeliveriesFail()])
        )
      )
    )
  );

  getLongtermDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_LONGTERM_DELIVERIES),
      map((action: actions.GetLongtermDeliveries) => action.payload),
      switchMap(({ params, status, showLoader, toggleDeliveriesAndStages }) =>
        this.http.getLongtermDeliveries(params, status).pipe(
          map(
            (res) =>
              new actions.GetLongtermDeliveriesSuccess({
                toggleDeliveriesAndStages,
                deliveries: res.deliveries,
                meta: res.meta,
              })
          ),
          catchError(() => [new actions.GetLongtermDeliveriesFail()])
        )
      )
    )
  );

  createDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_DELIVERY),
      map((action: actions.CreateDelivery) => action.payload),
      switchMap(({ delivery, openPanel, files, duplicateAfter }) =>
        this.http.createDelivery(delivery).pipe(
          mergeMap((response) => {
            if (!openPanel) {
              this.deliveriesService.closeOnetimeDeliveryForm();
              this.deliveriesService.closeDeliveryDetails();
            }

            this.deliveriesService.toggleLongtimeDelivery({ opened: false, date: null });

            const openDetailsPanel = {
              opened: true,
              type: DeliveryType.Onetime,
              status: this.deliveriesService.getStatus(response.status).status,
              id: response.id,
            };

            const actionList: Action[] = [new actions.CreateDeliverySuccess(response)];

            const path = [this.deliveriesService.getRedirectPath(response)];
            const actionObj = openPanel ? { path, openDetailsPanel } : { path };

            if (!duplicateAfter) {
              actionList.push(new fromAppState.Go(actionObj));
              this.deliveriesService.clearOnetimeDeliveryData();
            }

            if (files && files.length) {
              const deliveryFilesConfig: UpdateDeliveryFilesReq = {
                files,
                deliveryId: response.id,
                redirectConfig: !duplicateAfter ? actionObj : null,
              };

              actionList.push(new actions.UpdateDeliveryFiles(deliveryFilesConfig));
            }

            this.alertService.showInfo('deliveries_page.delivery_created_l');

            return actionList;
          }),
          catchError(() => [new actions.CreateDeliveryFail()])
        )
      )
    )
  );

  getDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY),
      map((action: actions.GetDelivery) => action.payload),
      switchMap((deliveryResponse) =>
        this.http.getDelivery(deliveryResponse).pipe(
          switchMap((delivery) =>
            forkJoin(
              delivery.deliveryMaterials.map((deliveryMaterial) =>
                this.http.getInspectionResults(deliveryMaterial.deliveryId, deliveryMaterial.id).pipe(
                  map((response) => ({
                    ...deliveryMaterial,
                    deliveryMaterialInspections: response[0].deliveryMaterialInspections,
                  }))
                )
              )
            ).pipe(
              map((deliveryMaterialsExtended) => {
                const deliveryMaterials = deliveryMaterialsExtended.map((deliveryMaterial) =>
                  this.translateItems.mapDeliveryMaterial(deliveryMaterial)
                );
                return new actions.GetDeliverySuccess({ ...delivery, deliveryMaterials });
              })
            )
          ),
          catchError(() => [new actions.GetDeliveryFail()])
        )
      )
    )
  );

  public updateDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_DELIVERY),
      map((action: actions.UpdateDelivery) => action.payload),
      switchMap(({ delivery, redirect = false, alertInfo, closePanel = true, redirectPath }) =>
        this.http.updateDelivery(delivery).pipe(
          mergeMap((response) => {
            if (closePanel) {
              this.deliveriesService.closeDeliveryDetails(true);
            }

            const actionsArray: Action[] = [new actions.UpdateDeliverySuccess(response)];

            if (redirect) {
              const currentRedirectPath = redirectPath || this.deliveriesService.getRedirectPath(response);

              const actionGoData = alertInfo
                ? { path: [currentRedirectPath], alertInfo }
                : { path: [currentRedirectPath] };

              actionsArray.push(new fromAppState.Go(actionGoData));
            }

            this.alertService.showInfo('deliveries_page.delivery_updated_l');

            return actionsArray;
          }),
          catchError(() => [new actions.UpdateDeliveryFail()])
        )
      )
    )
  );

  public restoreDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.RESTORE_DELIVERY),
      map((action: actions.RestoreDelivery) => action.payload),
      switchMap(({ delivery }) =>
        this.http.restoreDelivery(delivery).pipe(
          mergeMap((response) => {
            this.deliveriesService.closeDeliveryDetails(true);
            this.alertService.showInfo('deliveries_page.delivery_restored_l');
            const actionsList: Action[] = [new actions.RestoreDeliverySuccess(response)];

            return actionsList;
          }),
          catchError(() => [new actions.RestoreDeliveryFail()])
        )
      )
    )
  );

  public manuallyUpdateDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.MANUALLY_UPDATE_DELIVERY),
      map((action: actions.ManuallyUpdateDelivery) => action.payload),
      switchMap(({ id }) =>
        this.http.updateDeliveryUnloading(id).pipe(
          mergeMap((delivery) => {
            const actionsList: Action[] = [new actions.ManuallyUpdateDeliverySuccess(delivery)];

            return actionsList;
          }),
          catchError(() => [new actions.UpdateDeliveryFail()])
        )
      )
    )
  );

  public updateDeliveryFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_DELIVERY_FILES),
      map((action: actions.UpdateDeliveryFiles) => action.payload),
      mergeMap((deliveryFilesReqConfig) =>
        this.http.updateDeliveryFiles(deliveryFilesReqConfig).pipe(
          mergeMap((response) => {
            const { deliveryId, redirectConfig } = deliveryFilesReqConfig;

            const actionList: Action[] = [
              new actions.UpdateDeliveryFilesSuccess(response),
              new actions.GetDeliveryLogs(deliveryId),
            ];

            if (redirectConfig) {
              actionList.push(new fromAppState.Go(redirectConfig));
            }

            return actionList;
          }),
          catchError(() => [new actions.UpdateDeliveryFilesFail()])
        )
      )
    )
  );

  public openDeliveryFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.OPEN_DELIVERY_FILE),
      map((action: actions.OpenDeliveryFile) => action.payload),
      switchMap(({ deliveryId, fileId, openInNewTab }) =>
        this.http.openDeliveryFile(deliveryId, fileId).pipe(
          map((response) => {
            if (openInNewTab) {
              window.open(response.tempPubUrl, '_blank');
            }

            return new actions.OpenDeliveryFileSuccess(response);
          }),
          catchError(() => [new actions.OpenDeliveryFileFail()])
        )
      )
    )
  );

  public removeDeliveryFile = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.REMOVE_DELIVERY_FILE),
      map((action: actions.RemoveDeliveryFile) => action.payload),
      switchMap(({ deliveryId, fileId }) =>
        this.http.removeDeliveryFile(deliveryId, fileId).pipe(
          switchMap((response) => [
            new actions.RemoveDeliveryFileSuccess(response),
            new actions.GetDeliveryLogs(deliveryId),
          ]),
          catchError(() => [new actions.RemoveDeliveryFileFail()])
        )
      )
    )
  );

  checkInDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CHECK_IN_DELIVERY),
      map((action: actions.CheckInDelivery) => action.payload),
      switchMap((payload) =>
        this.http.checkInDelivery(payload).pipe(
          mergeMap((delivery) => {
            const successActions: Action[] = [
              new actions.CheckInDeliverySuccess(delivery),
              new fromAppState.Go({
                alertInfo: 'dashboard_page.check_in_success_l',
                path: ['dashboard'],
              }),
            ];

            let { deliveryNoteFilesConfig, deliveryVehicleFilesConfig } = payload;

            if (deliveryNoteFilesConfig) {
              deliveryNoteFilesConfig = this.extendCheckInFilesConfig(deliveryNoteFilesConfig, delivery);
              successActions.push(new actions.UpdateDeliveryFiles(deliveryNoteFilesConfig));
            }

            if (deliveryVehicleFilesConfig) {
              deliveryVehicleFilesConfig = this.extendCheckInFilesConfig(deliveryVehicleFilesConfig, delivery);
              successActions.push(new actions.UpdateDeliveryFiles(deliveryVehicleFilesConfig));
            }

            return successActions;
          }),
          catchError(() => [new actions.CheckInDeliveryFail()])
        )
      )
    )
  );

  checkOutDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CHECK_OUT_DELIVERY),
      map((action: actions.CheckOutDelivery) => action.payload),
      switchMap(({ deliveryId, identificator, redirectAfter = true }) =>
        this.http.checkOutDelivery({ deliveryId, identificator }).pipe(
          mergeMap((delivery) => {
            const actionsList: Action[] = [new actions.CheckOutDeliverySuccess(delivery)];

            if (redirectAfter) {
              actionsList.push(
                new fromAppState.Go({
                  alertInfo: 'dashboard_page.check_out_success_l',
                  path: ['dashboard'],
                })
              );
            }

            return actionsList;
          }),
          catchError(() => [new actions.CheckOutDeliveryFail()])
        )
      )
    )
  );

  rejectDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.REJECT_DELIVERY),
      map((action: actions.RejectDelivery) => action.payload),
      switchMap((payload) => {
        const { delivery, redirectAfter } = payload;
        this.deliveriesService.closeDeliveryDetails();
        return this.http.rejectDelivery(delivery).pipe(
          mergeMap((res) => {
            const actionsArray: Action[] = [new actions.RejectDeliverySuccess(res)];

            if (redirectAfter) {
              actionsArray.push(new fromAppState.Go({ path: ['dashboard'] }));
            }

            this.alertService.showInfo('alert.delivery_rejected_l');
            return actionsArray;
          }),
          catchError(() => [new actions.RejectDeliveryFail()])
        );
      })
    )
  );

  cancelDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CANCEL_DELIVERY),
      map((action: actions.CancelDelivery) => action.payload),
      switchMap((payload) =>
        this.http.cancelDelivery(payload).pipe(
          mergeMap((res) => {
            this.alertService.showInfo('alert.delivery_canceled_l');
            const actionsArray: Action[] = [new actions.CancelDeliverySuccess(res)];
            return actionsArray;
          }),
          catchError(() => [new actions.CancelDeliveryFail()])
        )
      )
    )
  );

  callDriver$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CALL_DRIVER),
      map((action: actions.CallDriver) => action.payload),
      switchMap(({ deliveryId }) =>
        this.http.callDriver(deliveryId).pipe(
          switchMap((res) => {
            const actionsList: Action[] = [new actions.CallDriverSuccess(res)];
            return actionsList;
          }),
          catchError((error) => [new actions.CallDriverFail()])
        )
      )
    )
  );

  remindDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.REMIND_DELIVERY),
      map((action: actions.RemindDelivery) => action.payload),
      switchMap(({ id }) =>
        this.http.remindDelivery(id).pipe(
          map(() => {
            this.alertService.showInfo('alert.send_reminder');
            return new actions.RemindDeliverySuccess();
          }),
          catchError(() => [new actions.RemindDeliveryFail()])
        )
      )
    )
  );

  removeDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.REMOVE_DELIVERY),
      map((action: actions.RemoveDelivery) => action.payload),
      switchMap((delivery) =>
        this.http.removeDelivery(delivery).pipe(
          map((res) => new actions.RemoveDeliverySuccess(res)),
          catchError((error) => [new actions.RemoveDeliveryFail()])
        )
      )
    )
  );

  checkDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CHECK_DELIVERY),
      map((action: actions.CheckDelivery) => action.payload),
      switchMap((payload) =>
        this.http.checkDelivery(payload).pipe(
          switchMap((res) => {
            const actionsList: Action[] = [new actions.CheckDeliverySuccess(res)];

            const vehiclesLimitReached =
              payload.type === 'checkIn' && res.deliveries.every((delivery) => !delivery.limitVehicle.current);

            if (vehiclesLimitReached) {
              this.alertService.showError('dashboard_page.limit_vehicles_l');
            }

            if (payload.checkoutAfter) {
              actionsList.push(
                new actions.CheckOutDelivery({
                  identificator: payload.identificator,
                  deliveryId: payload.deliveryId,
                  redirectAfter: false,
                })
              );
            }

            return actionsList;
          }),
          catchError((error) => [new actions.CheckDeliveryFail()])
        )
      )
    )
  );

  proposeDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.PROPOSE_DELIVERY),
      map((action: actions.ProposeDelivery) => action.payload),
      switchMap((proposeReq) =>
        this.http.proposeDelivery(proposeReq).pipe(
          map((propose) => {
            if (!propose || propose.length === 0) {
              this.alertService.showError('deliveries_page.empty_delivery_term_l');
            }

            const { unloadingTime } = proposeReq;
            const proposeToReturn = propose.map((prop) => ({ ...prop, unloadingTime }));

            return new actions.ProposeDeliverySuccess(proposeToReturn);
          }),
          catchError((error) => [new actions.ProposeDeliveryFail()])
        )
      )
    )
  );

  getManualModeDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_MANUAL_MODE_DELIVERIES),
      map((action: actions.GetManualModeDeliveries) => action.payload),
      switchMap((places) =>
        this.http.getManualModeDeliveries(places).pipe(
          map((res) => new actions.GetManualModeDeliveriesSuccess(res)),
          catchError((error) => [new actions.GetManualModeDeliveriesFail()])
        )
      )
    )
  );

  sendManualModeDeliveries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_MANUAL_MODE_DELIVERIES),
      map((action: actions.SendManualModeDeliveries) => action.payload),
      switchMap((data) =>
        this.http.sendManualModeDeliveries(data).pipe(
          mergeMap((res) => {
            this.manualModeService.clear();
            return [
              new actions.SendManualModeDeliveriesSuccess(res),
              new fromAppState.Go({
                path: ['dashboard'],
                alertInfo: 'alert.deliveries_moved_to_manual_mode_l',
              }),
            ];
          }),
          catchError((error) => [new actions.SendManualModeDeliveriesFail()])
        )
      )
    )
  );

  setManualModeDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SET_MANUAL_MODE_DELIVERY),
      map((action: actions.SetManualModeDelivery) => action.payload),
      switchMap(({ id, redirectAfter = false }) =>
        this.http.setManualModeDelivery(id).pipe(
          mergeMap(() => {
            const alertInfo = 'alert.delivery_moved_to_manual_mode_l';

            if (redirectAfter) {
              return [
                new fromAppState.Go({ path: ['dashboard'], alertInfo }),
                new actions.SetManualModeDeliverySuccess(),
              ];
            }

            this.alertService.showInfo(alertInfo);
            return [new actions.SetManualModeDeliverySuccess()];
          }),
          catchError(() => [new actions.SetManualModeDeliveryFail()])
        )
      )
    )
  );

  sendDeliveryCards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_DELIVERY_CARDS),
      map((action: actions.SendDeliveryCards) => action.payload),
      switchMap(({ id }) =>
        this.http.sendDeliveryCards(id).pipe(
          map(() => {
            this.alertService.showInfo('alert.delivery_cards_sent_l');
            return new actions.SendDeliveryCardsSuccess();
          }),
          catchError(() => [new actions.SendDeliveryCardsFail()])
        )
      )
    )
  );

  public downloadDeliveryCard$: Observable<actions.DownloadDeliveryCardSuccess | actions.DownloadDeliveryCardFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(actions.DOWNLOAD_DELIVERY_CARD),
        map((action: actions.DownloadDeliveryCard) => action.payload),
        switchMap((delivery) =>
          this.http.downloadDeliveryCard(delivery).pipe(
            map(() => new actions.DownloadDeliveryCardSuccess()),
            catchError(() => [new actions.DownloadDeliveryCardFail()])
          )
        )
      )
    );

  public getDeliveryContacts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY_CONTACTS),
      map((action: actions.GetDeliveryContacts) => action.payload),
      switchMap((response) =>
        this.http.getDeliveryContacts(response.id).pipe(
          map((contacts) => new actions.GetDeliveryContactsSuccess(contacts)),
          catchError((error) => [new actions.GetDeliveryContactsFail()])
        )
      )
    )
  );

  public getDeliveryUnloadingMethods$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY_UNLOADING_METHODS),
      switchMap(() =>
        this.http.getDeliveryUnloadingMethods().pipe(
          map((unloadingMethods) => new actions.GetDeliveryUnloadingMethodsSuccess(unloadingMethods)),
          catchError((error) => [new actions.GetDeliveryUnloadingMethodsFail()])
        )
      )
    )
  );

  public getDeliveryComments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY_COMMENTS),
      map((action: actions.GetDeliveryComments) => action.payload),
      switchMap(({ deliveryId }) =>
        this.http.getDeliveryComments(deliveryId).pipe(
          map((comments) => new actions.GetDeliveryCommentsSuccess(comments)),
          catchError((error) => [new actions.GetDeliveryCommentsFail()])
        )
      )
    )
  );

  public addDeliveryComments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ADD_DELIVERY_COMMENTS),
      map((action: actions.AddDeliveryComments) => action.payload),
      switchMap(({ deliveryId, deliveryComments }) =>
        this.http.addDeliveryComments(deliveryId, deliveryComments).pipe(
          map((comments) => new actions.AddDeliveryCommentsSuccess(comments)),
          catchError((error) => [new actions.AddDeliveryCommentsFail()])
        )
      )
    )
  );

  public getDistributors$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DISTRIBUTORS),
      switchMap(() =>
        this.http.getDistributors().pipe(
          map((distributors) => new actions.GetDistributorsSuccess(distributors)),
          catchError((error) => [new actions.GetDistributorsFail()])
        )
      )
    )
  );

  public createLongtimeDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_LONGTIME_DELIVERY),
      map((action: actions.CreateLongtimeDelivery) => action.payload.delivery),
      switchMap((delivery) =>
        this.http.createLongtimeDelivery(delivery).pipe(
          mergeMap((newDeliveryData: any) => {
            this.deliveriesService.toggleLongtimeDelivery({ opened: false, date: null });

            const deliveryMaterials = newDeliveryData.data.delivery.deliveryMaterials.map(
              (deliveryMaterial: DeliveryMaterial) => this.translateItems.mapDeliveryMaterial(deliveryMaterial)
            );

            this.alertService.showInfo('alert.longterm_delivery_created_l');

            return [
              new actions.CreateLongtimeDeliverySuccess({
                ...newDeliveryData.data.delivery,
                deliveryMaterials,
              }),
              new fromAppState.Go({ path: ['deliveries/expected/longterm'] }),
            ];
          }),
          catchError((error) => [new actions.CreateLongtimeDeliveryFail()])
        )
      )
    )
  );

  public updateLongtimeDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_LONGTIME_DELIVERY),
      map((action: actions.UpdateLongtimeDelivery) => action.payload),
      switchMap(({ delivery }) =>
        this.http.updateLongtimeDelivery(delivery).pipe(
          mergeMap((updatedDelivery) => {
            this.deliveriesService.toggleLongtimeDelivery({ opened: false, date: null });

            const deliveryMaterials = updatedDelivery.deliveryMaterials.map((deliveryMaterial) =>
              this.translateItems.mapDeliveryMaterial(deliveryMaterial)
            );

            const openDetailsPanel = {
              opened: true,
              type: updatedDelivery.typeDelivery,
              status: updatedDelivery.detailStatus,
              id: updatedDelivery.id,
            };

            this.alertService.showInfo('alert.updated_l');

            const actionsArray: Action[] = [
              new actions.UpdateLongtimeDeliverySuccess({
                ...updatedDelivery,
                deliveryMaterials,
              }),
              new fromAppState.Go({
                openDetailsPanel,
                path: ['deliveries/expected/longterm'],
              }),
            ];

            return actionsArray;
          }),
          catchError((error) => [new actions.UpdateLongtimeDeliveryFail()])
        )
      )
    )
  );

  public moveDeliveryStages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.MOVE_DELIVERY_STAGES),
      map((action: actions.MoveDeliveryStages) => action.payload),
      switchMap(({ deliveryStages, newDeliveryId, oldDelivery }) => {
        const stagesToRemove = oldDelivery.deliveryStages.map((stage) => ({
          ...stage,
          toRemove: true,
        }));

        return this.http.updateLongtimeDelivery({ id: oldDelivery.id, deliveryStages: stagesToRemove }).pipe(
          map((oldDeliveryRes) => {
            if (!newDeliveryId) {
              return new actions.CreateLongtimeDelivery({
                delivery: {
                  id: null,
                  ...oldDelivery,
                  deliveryStages,
                },
              });
            }

            return new actions.UpdateLongtimeDelivery({
              delivery: {
                id: newDeliveryId,
                deliveryStages,
              },
            });
          }),
          catchError(() => [new actions.MoveDeliveryStagesFail()])
        );
      })
    )
  );

  public createMaterialInspection$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.CREATE_MATERIAL_INSPECTION),
      map((action: actions.CreateMaterialInspection) => action.payload),
      switchMap((payload) =>
        this.http.createInspection(payload.body, payload.materialId, payload.deliveryId).pipe(
          mergeMap((response: CreateInspectionResponse) => {
            const deliveryMaterials = response.inspectionResults.map((deliveryMaterial) =>
              this.translateItems.mapDeliveryMaterial(deliveryMaterial)
            );

            return [
              new actions.CreateMaterialInspectionSuccess({
                deliveryMaterial: deliveryMaterials[0],
                deliveryInspectionStatus: response.deliveryInspectionStatus,
              }),
              new actions.GetDeliveryLogs(deliveryMaterials[0].deliveryId),
            ];
          }),
          catchError(() => [new actions.CreateMaterialInspectionFail()])
        )
      )
    )
  );

  public updateDeliveryMaterialAmount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_DELIVERY_MATERIAL),
      map((action: actions.UpdateDeliveryMaterial) => action.payload),
      switchMap((payload) =>
        this.http.updateDeliveryMaterialAmount(payload).pipe(
          mergeMap((response) => {
            this.alertService.showInfo('alert.delivery_material_amount_updated_l');
            return [
              new actions.UpdateDeliveryMaterialSuccess(response),
              new actions.GetDeliveryLogs(response[0].deliveryId),
            ];
          }),
          catchError(() => [new actions.UpdateDeliveryMaterialFail()])
        )
      )
    )
  );

  public getDeliveryLogs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_DELIVERY_LOGS),
      map((action: actions.GetDeliveryLogs) => action.payload),
      switchMap((deliveryId) =>
        this.http.getDeliveryLogs(deliveryId).pipe(
          map((logs) => new actions.GetDeliveryLogsSuccess({ logs, deliveryId })),
          catchError(() => [new actions.GetDeliveryLogsFail()])
        )
      )
    )
  );

  public sendOrders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_ORDERS),
      map((action: actions.SendOrders) => action.payload),
      switchMap((payload) =>
        this.http.sendOrders(payload).pipe(
          map(() => {
            this.alertService.showInfo('alert.email_sent_l');
            return new actions.SendOrdersSuccess();
          }),
          catchError(() => [new actions.UpdateDeliveryMaterialFail()])
        )
      )
    )
  );

  public getUnits$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_UNITS),
      switchMap(() =>
        this.http.getUnits().pipe(
          map((units) => new actions.GetUnitsSuccess(units)),
          catchError(() => [new actions.GetUnitsFail()])
        )
      )
    )
  );

  public assignStageToRun$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ASSIGN_STAGE_TO_RUN),
      map((action: actions.AssignStageToRun) => action.payload),
      switchMap(({ stageId, runId }) =>
        this.http.assignStageToRun(stageId, runId).pipe(
          map((deliveryRun) => {
            this.alertService.showInfo('alert.stage_assigned_l');
            this.deliveriesService.closeDeliveryRunPanel();

            return new actions.AssignStageToRunSuccess(deliveryRun);
          }),
          catchError(() => [new actions.AssignStageToRunFail()])
        )
      )
    )
  );

  public setDeliveryTimes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SET_DELIVERY_TIMES),
      map((action: actions.SetDeliveryTimes) => action.payload),
      switchMap(({ deliveryId, stageId, deliveryRunId, body }) =>
        this.http.setDeliveryRunsTimes(deliveryRunId, body).pipe(
          mergeMap((run) => [
            new actions.SetDeliveryTimesSuccess({
              deliveryId,
              stageId,
              run,
            }),
            new actions.UpdateDeliveryRun({ runId: run.id, data: { deliveryStageId: stageId } }),
          ]),
          catchError(() => [new actions.SetDeliveryTimesFail()])
        )
      )
    )
  );

  public sendTicketImage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_TICKET_IMAGE),
      map((action: actions.SendTicketImage) => action.payload),
      switchMap((data) =>
        this.http.sendTicketImage(data).pipe(
          map((response) => new actions.SendTicketImageSuccess(response)),
          catchError(() => [new actions.SendTicketImageFail()])
        )
      )
    )
  );

  public updateDeliveryRun$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_DELIVERY_RUN),
      map((action: actions.UpdateDeliveryRun) => action.payload),
      switchMap(({ runId, data }) =>
        this.http.updateDeliveryRun(runId, data).pipe(
          mergeMap((response) => [
            new actions.UpdateDeliveryRunSuccess({ run: response }),
            new UpdateStageInDeliveryRun({
              deliveryRunId: runId,
              deliveryStage: response.deliveryStage,
              deliveryTicketIdentifier: response.deliveryTicketIdentifier,
              batchStart: response.batchStart,
            }),
          ]),
          catchError(() => [new actions.UpdateDeliveryRunFail()])
        )
      )
    )
  );

  public sendOnetimeDeliveryTicketImage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_ONETIME_DELIVERY_TICKET_IMAGES),
      map((action: actions.SendOnetimeDeliveryTicketImages) => action.payload),
      switchMap(({ deliveryId, data }) =>
        this.http.sendOnetimeDeliveryTicketImages(deliveryId, data).pipe(
          map((response) => new actions.SendOnetimeDeliveryTicketImagesSuccess({ deliveryId, data: response })),
          catchError(() => [new actions.SendOnetimeDeliveryTicketImagesFail()])
        )
      )
    )
  );

  public getCurrentTicketImageUrl$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_CURRENT_TICKET_IMAGE_URL),
      map((action: actions.GetCurrentTicketImageUrl) => action.payload),
      switchMap(({ deliveryId, fileId }) =>
        this.http.getCurrentTicketImageUrl(deliveryId, fileId).pipe(
          map((file) => new actions.GetCurrentTicketImageUrlSuccess({ deliveryId, file })),
          catchError(() => [new actions.GetCurrentTicketImageUrlFail()])
        )
      )
    )
  );

  public getPourCard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_POUR_CARD),
      map((action: actions.GetPourCard) => action.payload),
      switchMap(({ deliveryRunId }) =>
        this.http.getPourCard(deliveryRunId).pipe(
          map((pourCard) => new actions.GetPourCardSuccess(pourCard)),
          catchError(() => [new actions.GetPourCardFail()])
        )
      )
    )
  );

  public getCubeTestsPurposes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_CUBE_TESTS_PURPOSES),
      switchMap(() =>
        this.http.getCubeTestsPurposes().pipe(
          map((purposes) => new actions.GetCubeTestsPurposesSuccess(purposes)),
          catchError(() => [new actions.GetCubeTestsPurposesFail()])
        )
      )
    )
  );

  public sendPourCard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SEND_POUR_CARD),
      map((action: actions.SendPourCard) => action.payload),
      switchMap(({ deliveryRunId, data }) =>
        this.http.sendPourCard(deliveryRunId, data).pipe(
          map((response) => {
            this.alertService.showInfo('alert.updated_l');
            return new actions.SendPourCardSuccess(response);
          }),
          catchError(() => [new actions.SendPourCardFail()])
        )
      )
    )
  );

  public updatePourCard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.UPDATE_POUR_CARD),
      map((action: actions.UpdatePourCard) => action.payload),
      switchMap(({ deliveryRunId, data }) =>
        this.http.updatePourCard(deliveryRunId, data).pipe(
          map((response) => {
            this.alertService.showInfo('alert.updated_l');
            return new actions.UpdatePourCardSuccess(response);
          }),
          catchError(() => [new actions.UpdatePourCardFail()])
        )
      )
    )
  );

  public getLongtermDeliveryTerms$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.GET_LONGTERM_DELIVERY_TERMS),
      map((action: actions.GetLongtermDeliveryTerms) => action.payload),
      switchMap((payload) =>
        this.http.getLongtermDeliveryTerms(payload).pipe(
          map((response) => new actions.GetLongtermDeliveryTermsSuccess(response)),
          catchError(() => [new actions.GetLongtermDeliveryTermsFail()])
        )
      )
    )
  );

  bookAgainDelivery$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.BOOK_AGAIN_DELIVERY),
      map((action: actions.BookAgainDelivery) => action.payload),
      switchMap(({ repeatedFromDeliveryId }) =>
        this.http.getDelivery({ id: repeatedFromDeliveryId }).pipe(
          switchMap((delivery) =>
            forkJoin(
              delivery.deliveryMaterials.map((deliveryMaterial) =>
                this.http.getInspectionResults(deliveryMaterial.deliveryId, deliveryMaterial.id).pipe(
                  map((response) => ({
                    ...deliveryMaterial,
                    deliveryMaterialInspections: response[0].deliveryMaterialInspections,
                  }))
                )
              )
            ).pipe(
              switchMap((deliveryMaterialsExtended) => {
                const deliveryMaterials = deliveryMaterialsExtended.map((deliveryMaterial) =>
                  this.translateItems.mapDeliveryMaterial(deliveryMaterial)
                );
                this.deliveriesService.openOnetimeDeliveryDuplicateAside({
                  ...delivery,
                  repeatedFromDeliveryId,
                  deliveryMaterials,
                  id: null,
                });

                return [new actions.GetDeliverySuccess({ ...delivery, deliveryMaterials })];
              })
            )
          ),
          catchError(() => [new actions.GetDeliveryFail()])
        )
      )
    )
  );

  private extendCheckInFilesConfig(config: UpdateDeliveryFilesReq, delivery: Delivery): UpdateDeliveryFilesReq {
    const {
      runNumber,
      typeDelivery,
      truckMixer,
      id: deliveryRunId,
      registrationNumber: onetimeRegistrationNumber,
    } = delivery;

    const isLongterm = !!runNumber;
    const isOnetime = typeDelivery && typeDelivery === DeliveryType.Onetime;

    if (isLongterm) {
      const { registrationNumber } = truckMixer;

      return {
        ...config,
        registrationNumber,
        deliveryRunId,
      };
    }

    if (isOnetime) {
      return {
        ...config,
        registrationNumber: onetimeRegistrationNumber,
      };
    }
  }
}

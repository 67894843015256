import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NZ_I18N, en_US, pl_PL, fr_FR, de_DE, ja_JP, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { CustomSerializer, effects, metaReducers, reducerProvider, reducerToken } from './state';
import { CoreModule } from './core';
import { ShellModule } from './core/shell/shell.module';
import { SharedModule } from './shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WildcardRoutingModule } from './wildcard-routing.module';
import { registerLocaleData, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeJa from '@angular/common/locales/ja';
import { GlobalErrorHandler } from '@ppgt/web/shared/core';
import { WebTranslocoConfigModule } from '@ppgt/web/transloco-config';
import { environment } from '@env/environment';
import * as dayjs from 'dayjs';
import { AppInitializerService } from './core/app-initializer.service';

import { DateTimeService } from '@app/core';

registerLocaleData(localePl, 'pl');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeJa, 'ja');

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  override toString() {
    return this.service.currentLang || 'en';
  }
}

export function initializeApp(appInitService: AppInitializerService) {
  return (): Promise<boolean> => {
    return appInitService.loadAppSettings();
  };
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule,
    ShellModule,
    SharedModule,
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(reducerToken, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    WildcardRoutingModule,
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    HttpClientModule,
    HammerModule,
    WebTranslocoConfigModule.forRoot(environment.production),
  ],
  declarations: [AppComponent],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppInitializerService],
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useClass: DynamicLocaleId,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useFactory: (dateTimeService: DateTimeService) => {
        const GMTzone = dayjs().tz(dateTimeService.timezone).offsetName();
        return { timezone: GMTzone };
      },
      deps: [DateTimeService],
    },
    {
      provide: NZ_I18N,
      useFactory: ({ service }) => {
        switch (service.currentLang) {
          case 'en':
            return en_US;
          case 'fr':
            return fr_FR;
          case 'pl':
            return pl_PL;
          case 'de':
            return de_DE;
          case 'ja':
            return ja_JP;
          default:
            return en_US;
        }
      },
      deps: [LOCALE_ID],
    },
    {
      provide: NZ_DATE_CONFIG,
      useFactory: (dateTimeService: DateTimeService) => {
        const firstDayOfWeek = dateTimeService.settings?.weekDays.start || 1;
        return {
          firstDayOfWeek,
        };
      },
      deps: [DateTimeService],
    },
    {
      provide: ErrorHandler,
      useClass: environment.production ? GlobalErrorHandler : ErrorHandler,
    },
    reducerProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

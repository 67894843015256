import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as minMax from 'dayjs/plugin/minMax';
import * as duration from 'dayjs/plugin/duration';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { DateRange } from '@ppgt/web/shared/domain';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);

export type DateType = string | number | Date | dayjs.Dayjs;
export type DateDuration = plugin.Duration;

export const diffInHoursAndMinutes = (from: DateType, to: DateType): string => {
  const startDate = dayjs(from);
  const endDate = dayjs(to);
  const hours = endDate.diff(startDate, 'hours');
  const minutes = endDate.diff(startDate, 'minutes');
  return `${hours}:${('0' + (minutes - hours * 60)).slice(-2)}`;
};

export const getCurrentWeek = (): DateRange => ({
  from: dayjs().startOf('isoWeek').toDate(),
  to: dayjs().endOf('isoWeek').toDate(),
});

export const getNextWeek = (): DateRange => ({
  from: dayjs().startOf('isoWeek').add(7, 'day').toDate(),
  to: dayjs().endOf('isoWeek').add(7, 'day').toDate(),
});

export const getCurrentMonth = (): DateRange => ({
  from: dayjs().startOf('month').toDate(),
  to: dayjs().endOf('month').toDate(),
});

export const getNextMonth = (): DateRange => ({
  from: dayjs().startOf('month').add(1, 'month').toDate(),
  to: dayjs().add(1, 'month').endOf('month').toDate(),
});

export const getTomorrow = (): DateRange => ({
  from: dayjs().add(1, 'day').hour(0).minute(0).second(0).toDate(),
  to: dayjs().add(1, 'day').hour(23).minute(59).second(59).toDate(),
});

export const getToday = (): DateRange => ({
  from: dayjs().startOf('date').toDate(),
  to: dayjs().endOf('date').toDate(),
});

export const getEndOfTime = (date: DateType = dayjs(), unit: dayjs.UnitTypeLong = 'day'): Date => {
  return dayjs(date).endOf(unit).toDate();
};

export const rangeFromInterval = (
  intervalUnit: dayjs.ManipulateType,
  count = 1,
  initialDate: DateType = dayjs()
): DateRange => {
  const date = dayjs(initialDate);
  const dateWithInterval = date.add(count, intervalUnit);
  const from = dayjs.min(date, dateWithInterval)?.toDate() as Date;
  const to = dayjs.max(date, dateWithInterval)?.toDate() as Date;
  return { from, to };
};

export const utcToTimezone = (date: DateType, timezone?: string): dayjs.Dayjs => {
  return dayjs.utc(date).tz(timezone);
};

export const durationBetween = (dateFrom: DateType, dateTo: DateType): DateDuration => {
  return dateFrom && dateTo ? dayjs.duration(dayjs(dateFrom).diff(dateTo)) : dayjs.duration(0);
};
